import { render, staticRenderFns } from "./certificare_guangdong.vue?vue&type=template&id=0c371270&scoped=true&"
import script from "./certificare_guangdong.vue?vue&type=script&lang=js&"
export * from "./certificare_guangdong.vue?vue&type=script&lang=js&"
import style0 from "./certificare_guangdong.vue?vue&type=style&index=0&id=0c371270&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c371270",
  null
  
)

export default component.exports