<template>
  <div>
    <div class="position_a">
      <div ref="imageWrapper" class="imageWrapper" :style="{
          backgroundImage: certificateData.backgroundPicUrl
            ? 'url(' + certificateData.backgroundPicUrl + ')'
            : '',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
        }">
        <!-- :style="{
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'transparent',
          gridArea: 'content',
          backgroundImage: cerBg ? 'url(' + cerBg + ')' : '',
        }"-->
        <div
          class="page"
          :style="cerBg ? '' : 'border: 1px solid #000000;'"
        >
          <div class="left">
            <h3 class="title zh">职业技能等级证书</h3>
            <p class="title_en en">Certificate of Occupational Skill Level</p>
            <div class="chinese zh" style="margin-top: 25px">
              <p>
                本证书由<i>{{ certificateData.template.empName }}</i>颁发，表明持证人通过本机构组织的职业技能等级认定，具备该职业（工种）相应技能等级水平。
              </p>
            </div>
            <div class="engilsh en">
              <p>
                This is to certify that the bearer has demonstrated
                corresponding competency in this occupation(job) for successful
                completion of the occupational skill level assessment organized
                by
                <span style="padding-left: 5px">
                  {{
                    certificateData.template.eName
                  }}
                </span>
              </p>
            </div>
            <div class="end">
              <p class="zh">
                <span class="zh">{{ certificateData.template.empName }}</span>
              </p>
              <p class="zh" style="margin-left: -2px">
                发证日期：
                <span class="zh">{{ certificateData.issue }}</span>
              </p>
              <p class="en">Issue date</p>
              <!-- 右边 -->
              <div class="zhang">
                <img style="width: 140px; height: 140px; opacity: 0.7" :src="certificateData.empUrl" alt />
              </div>
            </div>
            <p class="zh bottom" style="margin-top:55px">
              证书信息查询网址（<span class="en">Certificate Information</span>）：<span class="en">http://jndj.osta.org.cn/</span>
            </p>
            <p class="zh bottom">
              机构信息查询网址（<span class="en">Assessment Information</span>）：<span class="en">http://pjjg.osta.org.cn/</span>
            </p>
            <p class="en bottom bottom_end">No.{{ certificateData.template.certificateSerialNo }}</p>
          </div>
          <!-- 右边 -->
          <div class="right">
            <div class="right_top">
              <div class="header" v-if="certificateData.template.url">
                <img :src="certificateData.template.url" />
              </div>

              <div class="header" v-else>
                <div class="pic_text">
                  大&nbsp;一
                  <br />寸&nbsp;白
                  <br />底&nbsp;相
                  <br />片
                </div>
              </div>
              <div class="code" style="border: none">
                <img src="../assets/qrCode.png" />
              </div>
            </div>
            <div class="list" :class="certificateData.backgroundPicUrl ? '' : 'list2'">
              <div class="item">
                <div class="item_left">
                  <p class="zh">姓&nbsp;&nbsp;&nbsp;&nbsp;名：</p>
                  <p class="en">Name</p>
                </div>
                <div class="item_right">
                  <p class="zh">{{ certificateData.studentName }}</p>
                </div>
              </div>
              <div class="item">
                <div class="item_left">
                  <p class="zh">证件类型：</p>
                  <p class="en">ID Type</p>
                </div>
                <div class="item_right">
                  <p class="zh">{{ certificateData.template.idType }}</p>
                </div>
              </div>
              <div class="item">
                <div class="item_left">
                  <p class="zh">证件号码：</p>
                  <p class="en">ID No.</p>
                </div>
                <div class="item_right">
                  <p class="zh">{{ certificateData.template.idNo }}</p>
                </div>
              </div>
              <div class="item">
                <div class="item_left">
                  <p class="zh">职业名称：</p>
                  <p class="en">Occupation</p>
                </div>
                <div class="item_right">
                  <p class="zh">{{ certificateData.skillCategoryFirstName || "--" }}</p>
                </div>
              </div>
              <div class="item">
                <div class="item_left">
                  <p class="zh">工种/职业方向：</p>
                  <p class="en">Job</p>
                </div>
                <div class="item_right">
                  <p class="zh">{{ certificateData.skillCategorySecondName || "--" }}</p>
                </div>
              </div>
              <div class="item">
                <div class="item_left">
                  <p class="zh">职业技能等级：</p>
                  <p class="en">Skill Level</p>
                </div>
                <div class="item_right">
                  <p class="zh">{{ certificateData.skillCategoryThirdName }}</p>
                </div>
              </div>
              <div class="item">
                <div class="item_left">
                  <p class="zh">证书编号：</p>
                  <p class="en">Certificate No.</p>
                </div>
                <div class="item_right" style="margin-left: -12px">
                  <p class="zh">{{ certificateData.certificateNo }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      canvasUrl: null,
      cerBg: null,
    };
  },
  props: {
    certificateData: {
      type: Object
    }
  },
  mounted() {
    console.log()
    let that = this;
    let str = that.certificateData.backgroundPicUrl;
    that.cerBg = str;
    that.certificateData.issue =
      that.certificateData.issue.slice(0, 4) +
      "年" +
      that.certificateData.issue.slice(5, 7) +
      "月" +
      that.certificateData.issue.slice(8, 10) +
      "日";
    that.$nextTick(() => {
      html2canvas(that.$refs.imageWrapper, {
        scale: 4,
        scrollY: 0,
        scrollX: 0,
        allowTaint: true,
        taintTest: true
      }).then(canvas => {
        let url = canvas.toDataURL("image/png");
        that.$nextTick(() => {
          that.canvasUrl = url; //图片链接生成可预览
          that.$emit("getCanvasUrl", url);
        });
      });
    });
  },
  methods: {}
};
</script>
<style scoped lang="scss">
* {
  padding: 0;
  margin: 0;
}

.position_a {
  position: absolute;
  // z-index: 0;
  // display: none;
  top: 0;
  right: 9990px;
  z-index: 9;
  // word-spacing: 0;
  word-break: unset;
  // padding: 10px 40px 10px;
}
// .ulBook:hover {
//   box-shadow: rgb(183 177 177) 3px 1px 5px 5px;
// }
img {
  width: 100%;
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
}

.zh {
  font-family: "楷体", "楷体_GB2312";
  font-size:12pt;
}

.en {
  font-family: "Times New Roman";
}

.page {
  display: flex;
  // margin: 32px 35px;
  padding: 20mm;
  height: 170mm;
  width: 257mm;
  position: relative;
}

.zhang {
  width: 140px;
  height: 140px;
  position: absolute;
  bottom: -16px;
  left: 22px;
}

.page .left {
  width: 50%;
  padding: 0 40px 0 0;
}

.title {
  font-size: 30pt;
  font-weight: normal;
  text-align: center;
  margin-top: 50px;
  line-height: 24px;
  font-weight: 400;
}
.imageWrapper {
  position: absolute;
  color: #000000;
  // padding: 15mm 10mm;
  // height: 200mm;
  // width: 297mm;
  // width: 257mm;
  /*margin: 0 auto;*/
  /* height: 210mm;  */
  background-color: #ffffff;
  /* background-image: url(../../statics/img/cert_backgroup_pic.jpg);
        background-size: 100% 100%;
		background-repeat: no-repeat; */
}

.engilsh,
.chinese {
  font-size: 16pt;
  margin-top: 10px;
  text-indent: 2em;
  line-height: 24px;
}

.chinese p {
  text-align: justify;
  line-height: 30px;
}
.chinese p i{
  font-style: normal;
  text-decoration: underline;
}
.engilsh p {
  line-height: 30px;
  text-align: justify;
}
.chinese {
  margin-top: 20px;
}
/* .chinese p { */
// word-break: keep-all;
/* } */

.title_en {
  margin-top: 15px;
  font-size: 16pt;
  text-align: center;
}

.chinese span,
.engilsh span {
  text-decoration: underline;
  // white-space: pre-wrap;
  white-space: pre-wrap;
  // letter-spacing: -1px;
}

.end {
  margin-left: 40%;
  margin-top:80px;
  font-size: 12pt;
  position: relative;
}

.end p {
  line-height: 20px;
}

.bottom {
  font-size: 11pt;
  line-height: 20px;
}

.bottom_end {
  font-size: 12pt;
  margin-top: 20px;
  position: absolute;
  bottom: 70px;
}

/* 右边 */
.right {
  width: 50%;
  padding: 0 0 0 40px;
}

.right_top {
  width: 100%;
  display: flex;
  // justify-content: space-around;
  align-items: center;
  margin-top: 33px;
}

.right_top .header {
  // 2.5cm*3.5cm
  width: 25mm;
  height: 30mm;
  margin-left: 68px;

  /* border: 1px solid #000000; */
}

.right_top .code {
  margin-left: 112px;
  width: 30mm;
  height: 30mm;
}

.border {
  border: 1px solid #000000;
}

.list {
  width: 100%;
  margin-top: 60px;
}

.list .item {
  font-size: 12pt;
  display: flex;
  margin-bottom: 17px;
  justify-content: space-between;
  align-items: flex-start;
}

.item_left {
  text-align: justify;
}

.item_left .en {
  margin-top: 5px;
}

.item_right {
  display: flex;
  flex: 1;
  border-bottom: 1px solid #000000;
  font-size: 12pt;
  text-align: center;
}
.pic_text {
  margin-left: 30%;
  margin-top: 25%;
  font-family: "楷体", "楷体_GB2312", serif;
  font-size: 16px;
}
.item_right i {
  font-style: normal;
}

.item_right .zh {
  flex: 1;
}
</style>

